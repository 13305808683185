import axios from 'axios'
import {TBinanceSymbol} from '../hooks/useQueryGetBinanceSymbols'

const BINANCE_MAX_TIME_RANGE = 200 * 24 * 60 * 60 * 1000 // 200 days
const BINANCE_BASE_URL = 'https://www.binance.com/fapi/v1'
export const BINANCE_KLINE_WS = 'wss://fstream.binance.com/ws/btcusdt@kline_1m'

const contractType = 'PERPETUAL'
const limit = 1000

export interface BarData {
    time: number
    open: number
    high: number
    low: number
    close: number
    volume: number
}

interface IKlineParams {
    pair: string
    interval: string
    startTime?: number
    endTime?: number
}
const intervals = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    '1D': '1d',
    '1W': '1w',
    '1M': '1M',
}
export const convertResolution = (interval: string) => {
    return intervals[interval] || interval
}

export async function fetchContinuousKlineData(params: IKlineParams): Promise<BarData[]> {
    try {
        const interval = convertResolution(params.interval)
        const response = await axios.get(`${BINANCE_BASE_URL}/continuousKlines`, {
            params: {
                ...params,
                interval,
                contractType,
                limit,
            },
        })

        return response.data.map((d: any[]) => ({
            ...d,
            time: d[0],
            open: parseFloat(d[1]),
            high: parseFloat(d[2]),
            low: parseFloat(d[3]),
            close: parseFloat(d[4]),
            volume: parseFloat(d[5]),
        }))
    } catch (e) {
        console.log(e)
    }
}

export async function getAllSymbols() {
    try {
        const data = await axios.get(`${BINANCE_BASE_URL}/exchangeInfo`)
        const filteredSymbols = data.data?.symbols?.filter(symbol => symbol.status === 'TRADING')
        return filteredSymbols?.map(symbol => ({
            symbol: symbol.symbol,
            full_name: symbol.baseAsset + symbol.quoteAsset,
            description: symbol.baseAsset + symbol.quoteAsset,
            baseAsset: symbol.baseAsset,
            quoteAsset: symbol.quoteAsset,
            pricePrecision: symbol.pricePrecision,
            exchange: 'Binance',
        })) as TBinanceSymbol[]
    } catch (e) {
        console.log(e)
    }
}
