import {IBasicDataFeed, ResolutionString} from '../../../public/static/charting_library'
import {fetchContinuousKlineData} from './binanceApi'
import {RESOLVED_SYMBOL_CONFIG, WIDGET_CONFIG} from '../constants/chartConfig'
import {subscribeOnStream, unsubscribeFromStream} from './handler'
import {TBinanceSymbol} from '../hooks/useQueryGetBinanceSymbols'
import {TTradingViewChartType} from '../component/types/chart'
import {barCache} from './barCache'

export function createDatafeed(
    kind: TTradingViewChartType,
    symbols: TBinanceSymbol[],
    setInterval?: (interval: ResolutionString) => void,
    onError?: () => void,
): IBasicDataFeed {
    // Symbol resolution helpers
    const resolveSymbolError = (symbolName: string, onResolveErrorCallback: (error: string) => void) => {
        console.log('[resolveSymbol]: Cannot resolve symbol', symbolName)
        onError?.()
        onResolveErrorCallback('cannot resolve symbol')
    }

    const createSymbolInfo = (symbolItem: TBinanceSymbol) => ({
        ...RESOLVED_SYMBOL_CONFIG,
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        exchange: symbolItem.exchange,
        pricescale: 10 ** symbolItem.pricePrecision,
    })
    return {
        onReady: callback => setTimeout(() => callback(WIDGET_CONFIG)),
        searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
            const input = userInput.toLowerCase()
            const matchedSymbols = symbols?.filter(
                symbol =>
                    (exchange === '' || symbol.exchange === exchange) && symbol.full_name.toLowerCase().includes(input),
            )
            onResultReadyCallback(matchedSymbols)
        },
        resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
            const symbolItem = symbols?.find(({full_name}) => full_name === symbolName)

            if (!symbolItem) {
                resolveSymbolError(symbolName, onResolveErrorCallback)
                return
            }

            onSymbolResolvedCallback(createSymbolInfo(symbolItem))
        },
        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
            try {
                await barCache.throttleRequest()

                const data = await fetchContinuousKlineData({
                    pair: symbolInfo.name,
                    interval: resolution,
                    endTime: periodParams.to * 1000,
                })

                if (!data || data.length === 0) {
                    onHistoryCallback([], {noData: true})
                    return
                }

                barCache.set(symbolInfo.name, data[data.length - 1])
                setInterval?.(resolution)
                onHistoryCallback(data, {noData: false})
            } catch (error) {
                console.error('Error fetching historical data:', error)
                onErrorCallback(error)
            }
        },
        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
            const subscriberId = `${kind}_${subscriberUID}`
            const cachedBar = barCache.get(symbolInfo.name)

            subscribeOnStream(
                symbolInfo,
                resolution,
                bar => {
                    // Update cache with new bar
                    barCache.set(symbolInfo.name, bar)
                    onRealtimeCallback(bar)
                },
                subscriberId,
                onResetCacheNeededCallback,
                cachedBar,
            )
        },

        unsubscribeBars: subscriberUID => unsubscribeFromStream(`${kind}_${subscriberUID}`),
    }
}
