import {useQuery} from '@tanstack/react-query'
import {getAllSymbols} from '../utils/binanceApi'

export type TBinanceSymbol = {
    symbol: string
    full_name: string
    description: string
    baseAsset: string
    quoteAsset: string
    pricePrecision: number
    exchange: string
    type: string
}

export const useQueryGetBinanceSymbols = (enabled = true) => {
    return useQuery({
        enabled,
        staleTime: 1000 * 60 * 60 * 12, // 12 hour
        cacheTime: 1000 * 60 * 60 * 12, // 12 hour
        queryKey: ['binanceSymbols'],
        queryFn: async () => await getAllSymbols(),
    })
}
