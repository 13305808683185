import {BarData, convertResolution} from './binanceApi'
import {webSocketClient} from '@component/websocket/websocket'

export const REQUEST_LIMIT_MS = 200 // 요청 간 최소 간격 (ms)
export const delay = (ms: number): Promise<void> => new Promise<void>(resolve => setTimeout(() => resolve(), ms))

export interface SubscriberData {
    subscriberUID: string
    resolution: string
    lastBar: BarData
    handlers: {
        id: string
        callback: (bar: BarData) => void
    }[]
}
const channelToSubscription: Map<string, SubscriberData> = new Map()

export const handleSubscribeStreamData = data => {
    const channelString = `${data?.s?.toLowerCase()}@kline_${convertResolution(data?.k?.i) || '1m'}`
    const subscriptionItem = channelToSubscription.get(channelString)
    if (!subscriptionItem) return
    const bar: BarData = {
        time: data.k.t,
        open: parseFloat(data.k.o),
        high: parseFloat(data.k.h),
        low: parseFloat(data.k.l),
        close: parseFloat(data.k.c),
        volume: parseFloat(data.k.v),
    }
    subscriptionItem.lastBar = bar
    subscriptionItem.handlers.forEach(handler => handler.callback(bar))
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastBar,
) {
    const channelString = `${symbolInfo.name.toLowerCase()}@kline_${convertResolution(resolution) || '1m'}`
    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
    }
    let subscriptionItem = channelToSubscription.get(channelString)
    if (subscriptionItem) {
        subscriptionItem.handlers.push(handler)
        return
    }
    subscriptionItem = {
        subscriberUID,
        resolution,
        lastBar,
        handlers: [handler],
    }
    channelToSubscription.set(channelString, subscriptionItem)
    // console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString)

    webSocketClient.sendMessage({
        method: 'SUBSCRIBE',
        params: [channelString],
        id: 1,
    })
}

export function unsubscribeFromStream(subscriberUID: string) {
    for (const channelString of channelToSubscription.keys()) {
        const subscriptionItem = channelToSubscription.get(channelString)
        const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID)
        if (handlerIndex !== -1) {
            subscriptionItem.handlers.splice(handlerIndex, 1)
            if (subscriptionItem.handlers.length === 0) {
                // console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString)
                webSocketClient.sendMessage({
                    method: 'UNSUBSCRIBE',
                    params: [channelString],
                    id: 1,
                })
                channelToSubscription.delete(channelString)
                break
            }
        }
    }
}
