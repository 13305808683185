import {useRouter} from 'next/router'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'

import {IChartingLibraryWidget, LanguageCode} from '../../../public/static/charting_library'

import {handleSubscribeStreamData} from '../utils/handler'
import {createDatafeed} from '../utils/dataFeed'

import {createChartMarks, createTvWidget} from '../utils/chartUtils'
import {
    DEFAULT_BASE_SYMBOL,
    DEFAULT_QUOTE_SYMBOL,
    getTVWidgetThemeOptions,
    WIDGET_OPTIONS,
} from '../constants/chartConfig'

import {TTradingViewChartType} from '../component/types/chart'

import useCheckOrderSign from './useCheckOrderSign'
import {useQueryGetBinanceSymbols} from './useQueryGetBinanceSymbols'
import {useOrderAlertStore} from './useOrderAlertStore'
import {useChartSettingsStore} from './useChartSettingsStore'
import {DarkModeStore} from '@store/DarkmodeStore'
import {useWebSocket} from '@component/websocket/hooks/useWebSocket'
import {BINANCE_KLINE_WS} from '../utils/binanceApi'
import {showToast} from '@component/snackbar/WizToastMessage'

export const useTradingViewChart = (
    kind: TTradingViewChartType,
    chartContainerRef: React.MutableRefObject<HTMLDivElement>,
    enable: boolean = true,
) => {
    const {t} = useTranslation()
    const router = useRouter()

    const {data: symbols} = useQueryGetBinanceSymbols()

    const isDark = DarkModeStore(state => state.isDark)
    const {order, setOrder} = useOrderAlertStore()
    const {symbol: selectedSymbol, interval, setSymbol, setInterval, onSearchSymbolComplete} = useChartSettingsStore()
    const {enableOrderSign} = useCheckOrderSign()

    const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget>()

    useWebSocket(BINANCE_KLINE_WS, handleSubscribeStreamData)

    const resolveSymbolError = useCallback(() => {
        showToast(t('chat.chart.not.support.message'))
        setSymbol?.({
            base_symbol: DEFAULT_BASE_SYMBOL,
            quote_symbol: DEFAULT_QUOTE_SYMBOL,
        })
    }, [])

    const datafeed = useMemo(() => {
        if (!chartContainerRef?.current || !symbols) return
        return createDatafeed(kind, symbols, setInterval, resolveSymbolError)
    }, [chartContainerRef.current, symbols, kind])

    const clearTVWidget = useCallback(() => {
        if (!tvWidget) return
        tvWidget?.remove()
        setTvWidget(undefined)
    }, [tvWidget])

    const onCreateTVWidget = useCallback(() => {
        clearTVWidget()
        setTvWidget(
            createTvWidget({
                ...WIDGET_OPTIONS,
                ...getTVWidgetThemeOptions(isDark),
                container: chartContainerRef?.current,
                locale: router?.locale as LanguageCode,
                timezone: router?.locale === 'ko' ? 'Asia/Seoul' : Intl.DateTimeFormat().resolvedOptions().timeZone,
                interval,
                datafeed,
                symbol: selectedSymbol.base_symbol + selectedSymbol.quote_symbol,
                symbol_search_complete: onSearchSymbolComplete,
            }),
        )
    }, [router?.locale, datafeed, selectedSymbol, isDark, enable, interval, onSearchSymbolComplete, clearTVWidget])

    useEffect(() => {
        if (!datafeed || !enable) return

        onCreateTVWidget()

        return () => clearTVWidget()
    }, [datafeed, enable, router?.locale, datafeed, selectedSymbol, isDark])

    useEffect(() => {
        if (!enableOrderSign || !order || !tvWidget) return

        void createChartMarks(tvWidget, order, isDark, selectedSymbol)
        setOrder(undefined)
    }, [tvWidget, order, selectedSymbol, enableOrderSign])

    return {clearTVWidget, onCreateTVWidget}
}
